<!-- 验收记录-详情 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span>供货单位：</span>
        <span>{{ supplier_name }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>单据编号：</span>
        <span>{{ order_number }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>收货仓库：</span>
        <span>{{ warehouse_name }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>入库日期：</span>
        <span>{{ create_time }}</span>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>验收人：</span>
        <span>{{ acceptor }}</span>
      </FormItem>
      <span class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData"></Table> -->
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :option_page="option_page" :productList="listColumns" :productData="listData" highlight-row border :row-class-name="rowClassName"></Table>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr summary borderNone" v-if="listData && listData.length > 0">
          总数量：<span class="color389 marginRight20">{{ total_number }}</span> 合计金额：<span class="color389 marginRight20">{{ $utils.formatMoney(total_amount, true) }}</span>
          <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
        </div>
      </div>
      <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div> -->
    </div>
    <span class="pageBtn finger btnReset fr marginTop" @click="goBack">返回</span>

    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'detailsAcceptanceRecord',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      tabloading: false,
      total_number: '',
      total_amount: '',
      setupStatus: false,
      titleList: [],
      option_page: '24',
      // 表格数据
      listData: [],
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 136,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 136,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 75,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          width: 160,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 136,
        },
        {
          title: '生产日期',
          key: 'production_date',
          align: 'center',
          width: 136,
        },
        {
          title: '失效日期',
          key: 'valid_period',
          align: 'center',
          width: 136,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          width: 235,
        },
        {
          title: '注册证有效期',
          key: 'licence_valid_period',
          align: 'center',
          width: 160,
        },
        {
          title: '储运条件',
          key: 'storage_condition',
          align: 'center',
          minWidth: 260,
        },
      ],
      // 头部数据
      topData: {
        supplier: '上海微创医疗器械集团有限公司',
        receipt: 'RK202009130001',
        warehouse: '武汉仓',
        data: '2020-09-13',
        acceptor: '库管',
      },
      order_number: 0, // 单据编号
      warehouse_name: '', // 仓库
      user_name: '', // 验收人
      create_time: '', // 入库时间
      supplier_name: '', // 供货单位
    }
  },
  methods: {
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {})
    },
    // 反冲成功的行标红
    rowClassName() {
      if (this.$route.query.related_status == 3) return 'redRow'
      return ''
    },
    // 分页
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages)
    },
    // 获取表格数据
    getTableList(pages) {
      this.tabloading = true
      let obj = {
        id: this.$route.query.order_id,
        receipt_page: '1',
        page: pages.page,
        rows: pages.rows,
      }
      this.$http.get(this.$api.acceptanceManageDetail, obj, true).then(res => {
        this.tabloading = false
        if (res.status) {
          for (const item of res.data) {
            item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
            item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
            item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
            item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
            item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
            item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
            item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
            if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.business_license_valid_period = '长期'
            } else {
              item.business_license_valid_period = ''
            }
            if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.record_valid_period = '长期'
            } else {
              item.record_valid_period = ''
            }
            item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
            item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
            item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
            item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
            item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
            item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
            item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
            item.create_time = item.create_time ? this.$moment.unix(item.create_time).format('YYYY-MM-DD') : ''
          }
          this.total = res.total
          this.listData = res.data
          this.total_number = res.return_data.total_number
          this.total_amount = res.return_data.total_amount
          this.create_time = res.return_data.create_time ? this.$moment.unix(res.return_data.create_time).format('YYYY-MM-DD') : ''
          this.order_number = res.return_data.order_number
          this.warehouse_name = res.return_data.warehouse_name
          this.user_name = res.return_data.user_name
          this.supplier_name = res.return_data.supplier_name
          this.acceptor = res.return_data.acceptor
        }
      })
    },
    // 返回
    goBack() {
      this.$router.push('/acceptanceRecord')
    },
  },
  created() {
    this.getTableList(this.pages)
    this.queryOption()
  },
}
</script>

<style lang="less" scoped>
.marginTop {
  margin-top: 20px;
}
.borderNone {
  border: none;
}
</style>
